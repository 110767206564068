
function Projects() {
    const boxes ='hover:cursor-pointer project-box p-8 hover:shadow-lg hover:scale-[1.025] shadow-[var(--primary-color)]  border-[var(--primary-color)] border rounded-[1rem]  lg:w-[60%] h-fit w-full max-w-[900px]';
    const boxes_half ='hover:cursor-pointer project-box p-8 hover:shadow-lg hover:scale-[1.025] shadow-[var(--primary-color)]  border-[var(--primary-color)] border rounded-[1rem] lg:w-[40%]  w-full max-w-[600px]';
    const title = 'px-4 text-2xl poppins-bold';
    const tech = 'text-center uppercase bg-[var(--secondary-color)] p-1 px-4 rounded-lg border-2 border-[var(--primary-color)]';
    const descriptSort = 'px-4 leading-8 float-end  font-light text-[1rem]'
    const openLink = (url) => {
        window.open(url, "_blank");
    }
    return (
        <div className='md:px-[25px]'>
            <div className='flex justify-center flex-wrap gap-12 py-[25px]'>
                {/*EASE ATTENDANCE*/}
                <div className={boxes} onClick={() => openLink("https://www.easeattendance.com/")}>
                    <div className='flex justify-between flex-wrap'>
                        <h1 className={title}>
                            Ease Attendance
                        </h1>
                        <h1 className={descriptSort}>
                            Streamlining online meetings.
                        </h1>
                    </div>
                    <div className='py-4 text-sm'>
                        <ul className='list-disc px-4 py-2'>
                            <li className='py-1 '>
                                Quickly take attendance for classes over Zoom or Webex. Adopted at over
                                <span className='poppins-bold'> 5,000 institutions</span>, with over <span className='poppins-bold'>45,000 students tracked</span>.
                            </li>
                            <li className='py-1'>
                                Collaborated with Cisco Webex to enhance Ease Attendance by developing Webex's
                                REST API for developers. Leveraged these APIs to seamlessly incorporate Webex support.
                            </li>
                        </ul>
                    </div>
                    <div className='flex gap-2 pb-2 text-sm flex-wrap justify-center'>
                        <div className={tech}>Firecloud DB</div>
                        <div className={tech}>Web Dev</div>
                        <div className={tech}>Authentication</div>
                        <div className={tech}>Security/Encryption</div>
                        <div className={tech}>RestAPI</div>
                    </div>
                </div>
                {/* RIFT FOR INFINITE CAMPUS*/}
                <div className={boxes} onClick={() => openLink("https://riftapp.io/")}>
                    <div className='flex justify-between flex-wrap'>
                        <h1 className={title}>
                            Rift
                        </h1>
                        <h1 className={descriptSort}>
                            The Better Infinite Campus® Client
                        </h1>
                    </div>
                    <div className='py-4 text-sm'>
                        <ul className='list-disc px-4 py-2'>
                            <li className='py-1'>
                                Designed and developed app to view grades, assignments, and SIS information which allowed
                                students to optimize study plans and better plan their schedule.
                            </li>
                            <li className='py-1'>
                                Marketed and promoted use to grow app to over <span className='poppins-bold'> 15,000</span> users.
                            </li>
                        </ul>
                    </div>
                    <div className='flex gap-2 pb-2 text-sm flex-wrap justify-center'>
                        <div className={tech}>Swift</div>
                        <div className={tech}>SwiftUI</div>
                        <div className={tech}>App Development</div>
                    </div>
                </div>
            </div>
            {/*Two Col*/}
            <div className='flex justify-center flex-wrap gap-12 py-[25px]'>
                {/*OUTFIT OF THE DAY*/}
                <div className={boxes_half} onClick={() => openLink("https://github.com/OOTD-Virtual-Closet/OOTD-Swift")}>
                    <div className='flex justify-between flex-wrap'>
                        <h1 className={title}>
                            Outfit of the Day
                        </h1>
                        <h1 className={descriptSort}>
                            Digital Closet
                        </h1>
                    </div>
                    <div className='py-4 text-sm'>
                        <ul className='list-disc px-4 py-2'>
                            <li className='py-1'>
                                OOTD (Outfit Of The Day) is a virtual closet iOS application designed to help users
                                organize their wardrobe, plan outfits, and share their style with friends.
                            </li>
                            <li className='py-1'>
                                Built in a team of 5 using Agile Scrum methodology.
                            </li>
                            <li className='py-1'>
                                Completed as part of CS-307
                            </li>
                        </ul>
                    </div>
                    <div className='flex gap-2 pb-2 text-sm flex-wrap justify-center'>
                        <div className={tech}>Swift</div>
                        <div className={tech}>SwiftUI</div>
                        <div className={tech}>Google Cloud Platform</div>
                        <div className={tech}>Agile</div>
                    </div>
                </div>
                {/*My Cadences*/}
                <div className={boxes_half} onClick={() => openLink("https://mycadences.com/")}>
                    <div className='flex justify-between flex-wrap'>
                        <h1 className={title}>
                            MyCadences
                        </h1>
                        <h1 className={descriptSort}>
                            Music Analysis
                        </h1>
                    </div>
                    <div className='py-4 text-sm'>
                        <ul className='list-disc px-4 py-2'>
                            <li className='py-1'>
                                Create new playlists from old favorites. See valuable statistics. Take control of your
                                music.
                            </li>
                            <li className='py-1'>
                                Used GenAI to recommend playlists based on previous selections.
                            </li>
                            <li className='py-1'>
                                Pending Spotify approval for public use.
                            </li>
                            <li className='py-1'>
                                Created during 2024 Boilermake Hackathon.
                            </li>
                        </ul>
                    </div>
                    <div className='flex gap-2 pb-2 text-sm flex-wrap justify-center'>
                        <div className={tech}>Node.js</div>
                        <div className={tech}>Cloud Hosting</div>
                        <div className={tech}>RestAPI</div>
                        <div className={tech}>Web Development</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Projects;
